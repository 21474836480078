/* stylelint-disable */
a,
button,
.searchandfilter .sf-field-reset .search-filter-reset,
.searchandfilter .sf-field-reset [type=submit],
.gform_wrapper [type=submit],
[type='radio'],
[type='checkbox'] {
    &:focus-visible {
        outline-color: $primarycolor !important;
        outline-width: 2px !important;
        outline-style: dashed !important;
        outline-offset: 2px;
    }
}

:focus-visible {
    outline-color: $primarycolor !important;
    outline-width: 2px !important;
    outline-style: dashed !important;
    outline-offset: 2px;
}

.gfield input:focus, .gfield select:focus, .gfield textarea:focus {
    outline-color: $primarycolor !important;
    outline-width: 2px !important;
    outline-style: dashed !important;
    outline-offset: 2px;
}
/* stylelint-enable */

blockquote {
    border-left: 4px solid $primarycolor;
    padding-left: 20px;
    padding-bottom: 10px;
    font-style: italic;
    font-size: 1.75rem;
    display: flex;

    * {
        margin-bottom: 0 !important;
    }
}

body {
    font-family: $font-primary;
    font-size: $body-font-size;
    font-weight: $body-font-weight;
    line-height: $body-line-height;
    color: $body-font-color;

    &.body__menu__open {
        overflow: hidden;
        touch-action: none;
        -ms-touch-action: none;
    }
}

.main {
    padding: 0;
    opacity: 0;
}

.main__content {
    position: relative;
    z-index: 1;
    background-color: #fff;
}

.layer__iframe {
    padding-bottom: 80px;
    margin-top: -30px;

    iframe {
        border-radius: 40px;
        float: left;
    }
}

.layer__iframe__title {
    font-size: 2.188rem;
    font-family: $nexa-black;
    text-transform: uppercase;
    color: #000;
    margin-bottom: 30px;
    text-align: center;
}

.gif__hover {
    pointer-events: none;
    cursor: initial;
    position: relative;

    img {
        opacity: 0;
        transition: all 150ms ease-in-out;
        pointer-events: none;
        position: absolute;
        width: 150px;
        height: 150px;
        object-fit: cover;
        border-radius: 100%;
        bottom: 100%;
        left: 50%;
        transform: translateX(-50%);
    }

    span {
        pointer-events: all;
        position: relative;

        &::after {
            content: '';
            width: calc(100% - 5px);
            height: 0;
            position: absolute;
            left: 3px;
            bottom: -4px;
            border-bottom: 3px dotted $body-font-color;
        }

        &:hover {
            img {
                opacity: 1;
                transition: all 150ms ease-in-out;
            }
        }
    }
}

.layer {
    padding: 80px 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: $nexa-black;
}

h1,
h2 {
    text-transform: uppercase;
}

p {
    margin-bottom: 35px;
}

strong,
b {
    font-weight: 400;
    font-family: $graphik-bold;
}

.svg-inline--fa {
    width: 30px;
    height: auto;
}

.color {
    &--warempel {
        svg {
            color: #ff006c;
        }
    }

    &--exapps {
        svg {
            color: #131480;
        }
    }
}

ul {
    &.check {
        margin: 0 0 16px 0;
        padding: 0;
        list-style: none;

        li {
            position: relative;
            margin-bottom: 2px;
            padding-left: 30px;

            &:last-child {
                margin-bottom: 0;
            }

            &::before {
                position: absolute;
                top: 7px;
                left: 0;
                width: 18px;
                height: 18px;
                content: '';
                background-image: url("data:image/svg+xml,%3Csvg aria-hidden='true' style='color: %23f15a24;' focusable='false' data-prefix='far' data-icon='check' class='svg-inline--fa fa-check fa-w-16' role='img' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'%3E%3Cpath fill='currentColor' d='M435.848 83.466L172.804 346.51l-96.652-96.652c-4.686-4.686-12.284-4.686-16.971 0l-28.284 28.284c-4.686 4.686-4.686 12.284 0 16.971l133.421 133.421c4.686 4.686 12.284 4.686 16.971 0l299.813-299.813c4.686-4.686 4.686-12.284 0-16.971l-28.284-28.284c-4.686-4.686-12.284-4.686-16.97 0z'%3E%3C/path%3E%3C/svg%3E%0A");
                background-repeat: no-repeat;
                background-size: 18px;
            }
        }
    }
}

a {
    transition: all 300ms ease-in-out;

    &:hover,
    &:focus {
        text-decoration: none;
        color: $primarycolor;
    }
}

.acf-map {
    width: 100%;
    height: 500px;
    margin: 0;
}

.acf-map img {
    max-width: inherit !important;
}

.archive__content {
    padding: 35px 0 40px;
}

.archive__list {
    padding: 0 0 40px;
}

.slider__content {
    position: relative;

    a {
        position: relative;
        float: left;
    }
}

.slider__wrapper {
    overflow: hidden;
}

.slider__wrapper__top {
    position: relative;
}

.slider__play {
    position: absolute;
    z-index: 9;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    pointer-events: none;

    svg {
        width: 50px;
        height: 50px;
        color: #fff;
    }
}

.slider__top__prev,
.slider__top__next {
    position: absolute;
    z-index: 9;
    top: 50%;
    margin-top: -40px;

    svg {
        width: 80px;
        height: 80px;
        cursor: pointer;
        color: rgba(#fff, 0.7);
    }
}

.slider__top__prev {
    left: 10px;
}

.slider__top__next {
    right: 10px;
}

.slick-slide {
    cursor: pointer;

    &.slick-current {
        cursor: default;
    }

    a {
        &:focus {
            outline: none !important;
        }
    }

    &:focus {
        outline: none !important;
    }

    img {
        border-radius: $border-radius;
        outline: none !important;

        &:focus {
            outline: none !important;
        }
    }
}

.slider__nav {
    margin: 10px -5px 0;
    padding: 0 1px;
}

.slider__nav__item {
    position: relative;
    padding: 0 4px;

    .slider__play {
        svg {
            width: 25px;
            height: 25px;
        }
    }
}

.block {
    display: flex;
    flex-direction: column;
    overflow: hidden;
    height: calc(100% - 40px);
    margin-bottom: 40px;
    border: 1px solid #d5d5d5;
    border-radius: $block-radius;
    background-color: $white;

    &:hover {
        img {
            transform: scale(1.05);
        }

        .block__readmore {
            background-color: lighten($primarycolor, 5) !important;
        }
    }
}

.block__img__inner {
    display: block;
    overflow: hidden;
    width: 100%;

    img {
        width: 100%;
        max-width: 100%;
        transition: all 0.3s ease;
    }
}

.block__content {
    display: flex;
    align-items: flex-start;
    flex: 1;
    flex-direction: column;
    padding: 35px 45px 40px 45px;
    color: $body-font-color;
    background-color: $white;

    .btn {
        position: relative;
        display: inline-block;
        margin-top: auto;
        transition: all 0.3s;
        font-size: 0.813rem;

        svg {
            width: 7px;
            height: 18px;
            margin-top: 2px;
            margin-left: 10px;
            transition: all 0.3s ease;
        }
    }
}

.block__title {
    display: block;
    font-size: 1.188rem;
    font-weight: 700;
    line-height: 1.3;
    color: $primarycolor;
}

.block__subtitle {
    margin-top: 5px;
    margin-bottom: 20px;
    font-weight: 600;
}

.block__phone,
.block__email {
    position: relative;
    display: flex;
    align-items: center;
    padding-left: 34px;
    word-break: break-all;

    svg {
        position: absolute;
        top: 4px;
        left: 0;
        width: 16px;
        height: 16px;
        color: $primarycolor;
        fill: $primarycolor;
    }
}

.block__phone {
    margin-bottom: 4px;
}

.block__no__img {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 150px;
    background-color: #f9f9f9;
}

.block__date {
    display: block;
    margin-top: 7px;
    font-size: 0.875rem;
    font-weight: 700;
    color: #505050;

    svg {
        width: 12px;
        height: 13px;
        margin: -4px 8px 0 0;
        color: #505050;
    }
}

.block__summary {
    display: block;
    height: 100%;
    margin: 15px 0 25px;
    font-size: 1rem;
    line-height: 1.5;
    color: $body-font-color;
}

.block__list {
    padding: 19px 0 0;
}

.block__item {
    position: relative;
    display: block;
    margin: 0 0 8px 20px;
    color: $body-font-color;

    &:last-child {
        margin-bottom: 0;
    }

    &::before {
        position: absolute;
        top: 5px;
        left: -18px;
        width: 6px;
        height: 18px;
        content: "";
        background: url('../../images/angle-right-regular.svg') no-repeat;
        background-size: 6px 16px;
    }
}

.single__content {
    padding: 35px 0 120px 0;

    .btn--prev {
        margin-top: 30px;
    }

    h3 {
        margin-top: 30px;
    }
}

.accordion {
    margin: auto;
}

.alternate-bg-container {
    overflow: hidden;
    background: #000 url('../../images/background-lines-dark.db0b246.svg');
    background-repeat: repeat-y;
    background-size: 100%;
    background-attachment: fixed;
    image-rendering: -webkit-optimize-contrast;

    &.alternate-bg-container--no {
        margin-top: 120px;
    }
}

// Custom checkbox styling
.checkbox {
    display: flex;
    align-items: center;
    text-align: left;
}

.checkbox__input {
    display: none;
}

.checkbox__label {
    position: relative;
    display: flex;
    margin: 0;
    padding-right: 20px;
    cursor: pointer;
    line-height: 1.5;

    &::before {
        position: relative;
        display: inline-block;
        width: 15px;
        min-width: 15px;
        height: 15px;
        margin: 6px 10px 0;
        content: "";
        cursor: pointer;
        border: 1px solid #d5d5d5;
        border-radius: 3px;
        background-color: transparent;
    }
}

.checkbox .checkbox__input:checked + .checkbox__label::after {
    position: absolute;
    top: 8px;
    left: 15px;
    display: block;
    width: 5px;
    height: 9px;
    content: "";
    transform: rotate(45deg);
    border: solid #0079bf;
    border-width: 0 2px 2px 0;
}

.project__detail h1 {
    margin-bottom: 7px;
}

.frm-g-recaptcha {
    opacity: 0;
}

.container-fluid {
    padding-right: 40px;
    padding-left: 40px;

    .container-fluid {
        padding-right: 20px;
        padding-left: 20px;
    }
}

.pnf__content__inner {
    text-align: center;
}

.pnf__video__container {
    position: relative;
    margin: 30px 0 55px 0;

    video {
        width: 100%;
        border-radius: 20px;
    }
}

.error__video__mute {
    position: absolute;
    z-index: 9;
    right: 23px;
    bottom: 26px;
    width: 50px;
    height: 40px;
    cursor: pointer;
    background: none;
    border: none;

    &.unmuted {
        .error__video__mute__on {
            display: none;
        }

        .error__video__mute__off {
            display: flex;
            justify-content: center;
            height: 100%;
        }
    }
}

.error__video__mute__on {
    display: flex;
    justify-content: center;
    height: 100%;

    svg {
        height: 100%;
    }
}

.error__video__mute__off {
    display: none;

    svg {
        height: 100%;
    }
}

.error__video__pause {
    position: absolute;
    z-index: 9;
    left: 23px;
    bottom: 26px;
    width: 50px;
    height: 40px;
    cursor: pointer;
    background: none;
    border: none;

    &.unpause {
        .error__video__pause__on {
            display: none;
        }

        .error__video__pause__off {
            display: flex;
            justify-content: center;
            height: 100%;
        }
    }
}

.error__video__pause__on {
    display: flex;
    justify-content: center;
    height: 100%;

    svg {
        height: 100%;
    }
}

.error__video__pause__off {
    display: none;

    svg {
        height: 100%;
    }
}

.referer__popup {
    display: none;
}

.referer__popup__content {
    border-radius: $border-radius;
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
    width: 500px;

    .btn {
        margin: 0 auto;
        display: flex;
    }

    .fancybox-button {
        display: none;
    }
}

/* stylelint-disable */

@media all and (-ms-high-contrast: none),
(-ms-high-contrast: active) {
    .block {
        display: block;
        flex: none;
    }

    .block__content {
        display: block;
    }
}

/* stylelint-enable */

@media (max-width: 1199px) {
    .block__content {
        padding: 25px 35px 30px;
    }

    .layer__iframe__title {
        font-size: 1.75rem;
        line-height: 1.4;
    }

    .slider__top__prev,
    .slider__top__next {
        margin-top: -30px;
    }

    .slider__top__prev svg,
    .slider__top__next svg {
        width: 60px;
        height: 60px;
        cursor: pointer;
        color: rgba(255, 255, 255, 0.7);
    }

    .slider__top__prev {
        left: 10px;
    }

    .slider__top__next {
        right: 10px;
    }
}

@media (max-width: 767px) {
    body {
        font-size: 0.875rem;
    }

    p {
        margin-bottom: 25px;
    }

    h2 {

    }

    .layer__iframe {
        padding-bottom: 50px;
        margin-top: 0;
    }

    .layer__iframe__title {
        font-size: 1.375rem;
    }

    ul.check li::before {
        top: 2px;
        width: 16px;
        height: 16px;
        background-size: 16px;
    }

    .layer {
        padding: 40px 0;
    }

    .layer.archive__list {
        padding-top: 0;
        padding-bottom: 10px;
    }

    .layer.archive__content {
        padding-bottom: 20px;
        padding-top: 0;
        margin-top: -25px;
    }

    .block__summary {
        font-size: 0.938rem;
    }

    .single__content {
        padding: 0 0 50px 0;
        margin-top: -25px;
    }

    .single__content .btn--prev {
        margin-top: 10px;
    }

    .referer__popup__content {
        width: calc(100% - 60px);
    }
}

@media (max-width: 575px) {

}

@media (max-width: 374px) {
    .container-fluid {
        padding-right: 20px;
        padding-left: 20px;
    }
}
