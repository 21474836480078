/** Import Bootstrap functions */
@import "~bootstrap/scss/functions";

$slick-loader-path: "~slick-carousel/slick/";
$slick-font-path: "~slick-carousel/slick/fonts/";

// Colors
$primarycolor: #f15a24;
$secondarycolor: #ec821c;
$tertiarycolor: #dce1e3;

$graphik-regular: 'Graphik-Regular', sans-serif;
$graphik-semibold: 'Graphik-Semibold', sans-serif;
$graphik-bold: 'Graphik-Bold', sans-serif;
$nexa-regular: 'Nexa-Regular', sans-serif;
$nexa-black: 'Nexa-Black', sans-serif;

$font-primary: $graphik-regular;
$font-secondary: '';
$body-font-size: 1.25rem;
$body-font-weight: 400;
$body-line-height: 1.6;
$body-font-color: #000;

// Headings: h1,h2,h3,h4,h5,h6
$headings-margin-bottom: 20px;
$headings-font-family: $nexa-black;
$headings-font-weight: 400;
$headings-line-height: 1.3;
$headings-color: #000;

// Header:
$header-height: 130px;

$border-radius: 20px;
$border-radius-form: 10px;
$border-radius-btn: 100px;
$block-radius: 8px;

$theme-colors: (
    primary: $primarycolor
);

/** Bootstrap navbar fix (https://git.io/fADqW) */
$navbar-dark-toggler-icon-bg: none;
$navbar-light-toggler-icon-bg: none;

//Bootstrap
$grid-gutter-width: 40px !default;

$enable-responsive-font-sizes: true;
$h1-font-size: 3.125rem;
$h2-font-size: 2.188rem;
$h3-font-size: 1.625rem;
$h4-font-size: 1.375rem;
$h5-font-size: 1.25rem;
