.footer {
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    z-index: -1;
    background-color: #dce1e3;
    padding: 130px 0 150px 0;
    text-align: center;

    .social__icons {
        justify-content: center;
    }

    .social__icon__item {
        margin-right: 18px;

        svg {
            width: auto;
            height: 30px;
            transition: all 0.3s;
        }

        &:hover {
            svg {
                color: lighten($primarycolor, 8);
            }
        }
    }
}

.footer__logos {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 60px;
    margin-bottom: 115px;

    a,
    img {
        display: block;
    }
}

.footer__logo {
    max-width: 100%;
}

.footer__title {
    color: #b9c9d1;
    font-size: 2.625rem;
    text-transform: uppercase;
    font-family: $headings-font-family;
    margin-bottom: 22px;
    line-height: 1.3;
}

@media (max-width: 991px) {
    .footer {
        padding: 70px 0 80px 0;
    }
}

@media (max-width: 767px) {
    .footer {
        padding: 50px 0 60px 0;

        .social__icon__item {
            margin-right: 14px;

            svg {
                height: 24px;
            }
        }
    }

    .footer__logos {
        gap: 40px;
    }

    .footer__title {
        font-size: 2rem;
    }
}

@media (max-width: 575px) {
    .footer__logos {
        flex-wrap: wrap;
        gap: 30px;
    }

    .footer__logo {
        max-height: 30px;
    }
}
