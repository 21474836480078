.front__quiz {
    padding: 110px 0;

    .front__quiz__toptitle {
        font-size: 2.125rem;
        display: block;
        text-align: center;
        font-family: $nexa-black;
        text-transform: uppercase;
        line-height: 1.4;
    }

    .front__quiz__title {
        line-height: 1.4;
        font-family: $nexa-black;
        text-transform: uppercase;
        display: block;
        text-align: center;
        font-size: 4.75rem;
        color: transparent;
        -webkit-text-stroke-width: 3px;
        -webkit-text-stroke-color: $primarycolor;
        margin-bottom: 30px;
    }

    .front__quiz__content {
        padding: 65px 70px 50px 70px;
        border: 3px solid #e7edf0;
        border-radius: 30px;
        position: relative;
    }

    .front__quiz__content__nsfw__suprise {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        border-radius: 25px;
        z-index: 100;
        overflow: hidden;
        display: none;

        &.front__quiz__content__nsfw__suprise--active {
            display: block;
        }

        .front__quiz__content__nsfw__suprise__video {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    .front__quiz__content__nsfw {
        color: #000;
        padding: 5px;
        font-family: $nexa-black;
        font-size: 0.875rem;
        border: 0;
        background: transparent;
        display: flex;
        align-items: center;
        position: absolute;
        right: 40px;
        top: 40px;
        z-index: 120;

        &:active,
        &:focus {
            outline: 0;
        }

        .front__quiz__content__nsfw__toggle {
            position: relative;
            width: 50px;
            height: 30px;
            margin-top: -2px;
            margin-left: 10px;
            display: inline-block;
            background: #eff3f5;
            border: 3px solid #e7edf0;
            border-radius: 30px;

            &.front__quiz__content__nsfw__toggle--active {
                background: #000;
            }

            .front__quiz__content__nsfw__toggle__circle {
                position: absolute;
                left: 2px;
                top: 2px;
                width: 20px;
                height: 20px;
                border-radius: 100%;
                background: #eb7a7a;
                transition: all 200ms ease-in-out;

                &.front__quiz__content__nsfw__toggle__circle--active {
                    left: 22px;
                    background: #ff9400;
                }
            }
        }
    }

    .front__quiz__content__questions__count {
        position: absolute;
        font-size: 1rem;
        right: 35px;
        bottom: 25px;
    }

    .front__quiz__content__questions__count__total__current {
        color: $body-font-color;
    }

    .front__quiz__content__questions__count__total {
        color: #b9c9d1;

        &::before {
            content: '/';
            display: inline-block;
            margin: 0 5px;
        }
    }

    .front__quiz__content__toptitle {
        padding: 0 52px;
        font-family: $nexa-regular;
        font-size: 1.75rem;
        color: #000;
        position: absolute;
        top: 0;
        left: 50%;
        transform: translateX(-50%) translateY(-50%);
        background: $white;
    }

    .front__quiz__content__questions__results__title {
        font-size: 1.688rem;
        text-align: center;
        display: block;
        padding: 30px 0 10px 0;
    }

    .front__quiz__content__questions__results__amount__title {
        font-size: 2rem;
        display: block;
        font-family: $nexa-black;
        text-align: center;
    }

    .front__quiz__content__questions__results__amount__count {
        color: $primarycolor;
        margin-left: 10px;
        font-size: 2.813rem;
    }

    .front__quiz__content__questions__results {
        position: absolute;
        opacity: 0;
        top: 0;
        width: 100%;
    }

    .front__quiz__content__questions__results__item {
        display: none;
    }

    .front__quiz__content__questions {
        position: relative;
        overflow: hidden;

        .front__quiz__content__questions__loading {
            display: flex;
            justify-content: center;
            padding: 110px 60px;
        }

        .front__quiz__content__questions__item {
            position: absolute;
            opacity: 0;
            top: 0;
            width: calc(100% + 40px);
        }

        .front__quiz__content__questions__wrap {
            padding: 55px 0 30px;
            text-align: center;
            display: flex;
            flex-direction: column;
            flex-wrap: wrap;
            justify-content: center;
            align-items: center;
        }

        .front__quiz__content__questions__anwser {
            border-radius: 60px;
            font-size: 1.313rem;
            line-height: 28px;
            font-family: $graphik-regular;
            background: #eff3f5;
            padding: 10px 30px;
            margin: 0 30px 15px 30px;
            width: calc(100% - 60px);
            border: 3px solid $white;
            transition: all 200ms ease-in-out;

            &.front__quiz__content__questions__anwser--false {
                background: #eb7a7a;
            }

            &.front__quiz__content__questions__anwser--true {
                background: #7aeb90;
            }

            &:active,
            &:focus {
                outline: 0;
            }

            &:hover {
                box-shadow: 0 6px 12px #e7edf0;
            }
        }

        .front__quiz__content__questions__title {
            font-size: 1.688rem;
            line-height: 36px;
            font-family: $nexa-black;
            margin-bottom: 25px;
        }

        .front__quiz__content__questions__video {
            border-radius: 30px;
            background: #eee;
        }
    }
}

@media screen and (max-width: 991px) {
    .front__quiz {
        padding: 80px 0;

        .front__quiz__toptitle {
            font-size: 2.25rem;
        }

        .front__quiz__title {
            font-size: 4.563rem;
            line-height: 1.2;
            margin-bottom: 50px;
        }

        .front__quiz__content__toptitle {
            font-size: 1.5rem;
        }

        .front__quiz__content {
            padding: 70px 50px 50px 50px;
        }

        .front__quiz__content__nsfw {
            top: inherit;
            left: 40px;
            bottom: 30px;
        }

        .front__quiz__content__questions__results__title {
            padding: 20px 0 40px 0;
            font-size: 1.375rem;
        }

        .front__quiz__content__questions {
            .front__quiz__content__questions__anwser {
                font-size: 1.063rem;
                line-height: 24px;
                margin-bottom: 8px;
            }

            .front__quiz__content__questions__title {
                font-size: 1.375rem;
                line-height: 30px;
            }

            .front__quiz__content__questions__wrap {
                padding-top: 15px;
                padding-left: 0;
                padding-right: 0;
            }

            .front__quiz__content__questions__video {
                max-width: 240px;
                max-height: 240px;
                width: 100%;
                object-fit: cover;
            }
        }
    }
}

@media screen and (max-width: 767px) {
    .front__quiz {
        padding: 50px 0;

        .front__quiz__toptitle {
            font-size: 1.813rem;
        }

        .front__quiz__title {
            -webkit-text-stroke-width: 1px;
            font-size: 1.813rem;
            margin-bottom: 40px;
        }

        .front__quiz__content__toptitle {
            font-size: 1.125rem;
            padding: 0 10px;
            min-width: 150px;
        }

        .front__quiz__content {
            padding: 30px 25px 50px 25px;
        }

        .front__quiz__content__questions__results__amount__title {
            font-size: 1.688rem;
        }

        .front__quiz__content__questions__results__amount__count {
            font-size: 2.5rem;
        }

        .front__quiz__content__questions__results__title {
            font-size: 1.125rem;
            padding: 10px 0 40px;
        }

        .front__quiz__content__nsfw {
            left: 18px;
            bottom: 14px;
        }

        .front__quiz__content__questions__count {
            font-size: 0.875rem;
            bottom: 25px;
        }

        .front__quiz__content__questions {
            .front__quiz__content__questions__anwser {
                font-size: 0.938rem;
                margin: 0 0 7px 0;
                width: 100%;
            }

            .front__quiz__content__questions__wrap {
                padding-top: 0;
                padding-bottom: 20px;
            }

            .front__quiz__content__questions__title {
                margin-bottom: 20px;
                font-size: 1.125rem;
                line-height: 1.4;
            }

            .front__quiz__content__questions__video {
                display: flex;
                margin-left: auto;
                margin-right: auto;
                margin-bottom: 22px;
                max-width: 260px;
                max-height: 260px;
            }
        }
    }
}
