@font-face {
    font-family: 'Graphik-Regular';
    src: url('../../fonts/Graphik-Regular.eot');
    src: url('../../fonts/Graphik-Regular.eot?#iefix') format('embedded-opentype'), url('../../fonts/Graphik-Regular.svg#Graphik-Regular') format('svg'), url('../../fonts/Graphik-Regular.ttf') format('truetype'), url('../../fonts/Graphik-Regular.woff') format('woff'), url('../../fonts/Graphik-Regular.woff2') format('woff2');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Graphik-Semibold';
    src: url('../../fonts/Graphik-Semibold.eot');
    src: url('../../fonts/Graphik-Semibold.eot?#iefix') format('embedded-opentype'), url('../../fonts/Graphik-Semibold.svg#Graphik-Semibold') format('svg'), url('../../fonts/Graphik-Semibold.ttf') format('truetype'), url('../../fonts/Graphik-Semibold.woff') format('woff'), url('../../fonts/Graphik-Semibold.woff2') format('woff2');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Graphik-Bold';
    src: url('../../fonts/Graphik-Bold.eot');
    src: url('../../fonts/Graphik-Bold.eot?#iefix') format('embedded-opentype'), url('../../fonts/Graphik-Bold.svg#Graphik-Bold') format('svg'), url('../../fonts/Graphik-Bold.ttf') format('truetype'), url('../../fonts/Graphik-Bold.woff') format('woff'), url('../../fonts/Graphik-Bold.woff2') format('woff2');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Nexa-Regular';
    src: url('../../fonts/Nexa-Regular.eot');
    src: url('../../fonts/Nexa-Regular.eot?#iefix') format('embedded-opentype'), url('../../fonts/Nexa-Regular.svg#Nexa-Regular') format('svg'), url('../../fonts/Nexa-Regular.ttf') format('truetype'), url('../../fonts/Nexa-Regular.woff') format('woff'), url('../../fonts/Nexa-Regular.woff2') format('woff2');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Nexa-Book';
    src: url('../../fonts/Nexa-Book.eot');
    src: url('../../fonts/Nexa-Book.eot?#iefix') format('embedded-opentype'), url('../../fonts/Nexa-Book.svg#Nexa-Book') format('svg'), url('../../fonts/Nexa-Book.ttf') format('truetype'), url('../../fonts/Nexa-Book.woff') format('woff'), url('../../fonts/Nexa-Book.woff2') format('woff2');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Nexa-Black';
    src: url('../../fonts/Nexa-Black.eot');
    src: url('../../fonts/Nexa-Black.eot?#iefix') format('embedded-opentype'), url('../../fonts/Nexa-Black.svg#Nexa-Black') format('svg'), url('../../fonts/Nexa-Black.ttf') format('truetype'), url('../../fonts/Nexa-Black.woff') format('woff'), url('../../fonts/Nexa-Black.woff2') format('woff2');
    font-weight: normal;
    font-style: normal;
}
