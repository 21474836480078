@import "~bootstrap/scss/bootstrap";

@media (min-width: 1200px) {
    .container {
        max-width: 1016px;
    }

    .container-fluid {
        max-width: 1016px;
    }

    .container-fluid--md {
        max-width: 1220px;
    }

    .container-fluid--lg {
        max-width: 1494px;
    }
}
