.marker__inner {
    display: block;
    padding: 8px 10px 8px 10px;
}

.marker__title {
    display: block;
    margin: 0 0 8px 0;
    font-size: 1.125rem;
    font-weight: 700;
    text-align: center;
    color: $primarycolor;
}

.marker__item {
    display: block;
    width: 100%;
    margin-bottom: 5px;
    font-size: 0.938rem;
    text-align: center;

    &.btn {
        margin: 15px 0 0 0;
    }
}

.contact__form__inner {
    padding: 35px 40px;
    border-radius: $border-radius;
    background-color: #f3f3f3;

    .gfield {
        input,
        select,
        textarea {
            border: 1px solid transparent;
            background-color: #fff;
        }

        textarea {
            max-height: 180px;
        }
    }
}

.contact__form__title {
    display: block;
    margin-bottom: 15px;
    font-size: 1.125rem;
    font-weight: 700;
}

.contact__form__text {
    display: block;
    margin-bottom: 25px;
}

.contact__company {
    margin-bottom: 10px;
    font-size: $body-font-size;
}

.contact__address {
    span {
        display: block;
    }
}

.contact__accessibility {
    margin: 0 0 20px;
    padding: 0;
    list-style: none;
    line-height: 31px;
}

.contact__icon {
    margin-right: 10px;

    svg {
        width: 16px;
        height: 16px;
        color: $primarycolor;
        fill: $primarycolor;
    }
}

.contact__line {
    display: flex;
    line-height: 31px;
}

.contact__text {
    transition: all 0.3s;
    color: $body-font-color;
}

.contact__details {
    margin-bottom: 20px;
}

.contact__location {
    margin-top: 40px;
    margin-bottom: 40px;

    &:last-child {
        margin-bottom: 0;
    }
}

.contact__link {
    display: flex;
    align-items: center;
    justify-content: flex-start;

    &:hover {
        .contact__text {
            color: $primarycolor;
        }
    }
}

.contact__line__col {
    width: 75px;
    font-weight: bold;
}

.contact__image {
    max-width: 90%;
    margin-top: 30px;
}

@media screen and (max-width: 1199px) {

}

@media screen and (max-width: 991px) {
    .contact__main {
        margin-bottom: 40px;
    }
}

@media screen and (max-width: 767px) {
    .contact__form__inner {
        padding: 27px 30px 33px 30px;
    }

    .contact__image {
        max-width: 100%;
        margin-top: 35px;
    }
}

@media screen and (max-width: 575px) {

}
