.banner {
    position: relative;
    height: 568px;
    overflow: hidden;
    object-fit: cover;

    &::after {
        content: '';
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;
        background-color: rgba(#003277, 0.29);
    }

    img {
        width: 100%;
        height: 568px;
        object-fit: cover;
    }
}

.banner__top {
    position: relative;
    display: flex;
    align-items: center;
    padding: 18px 150px;
    background: #fff;
    z-index: 9;
}

.banner__logo {
    .menu__left__logo {
        display: block;
        opacity: 1;
    }

    img {
        width: 416px !important;
        height: auto !important;
    }
}

.front-page-data {
    .banner {
        position: relative;
        height: 858px;

        &::after {
            display: none;
        }

        img {
            width: 100%;
            height: 858px;
            object-fit: cover;
        }
    }
}

.banner__content {
    position: relative;
    width: 100%;
    overflow: hidden;
    top: -70px;
    font-family: $nexa-black;
    font-size: 4.375rem;
    line-height: 70px;
    text-transform: uppercase;

    span {
        display: inline-block;
        padding: 0 40px;
    }
}

.banner__content__top {
    color: transparent;
    -webkit-text-stroke-width: 3px;
    -webkit-text-stroke-color: #efefef;
    white-space: nowrap;
    position: relative;
    left: -400px;
    transform: translate(0, 0);
}

.banner__content__bot {
    white-space: nowrap;
    color: #eff3f5;
    position: relative;
    left: -200px;
    transform: translate(0, 0);
}

@media screen and (max-width: 1199px) {
    .front-page-data .banner {
        position: relative;
        height: 650px;
    }

    .front-page-data .banner img {
        height: 650px;
    }

    .banner {
        height: 400px;
    }

    .banner img {
        height: 400px;
    }

    .banner__content {
        font-size: 2.5rem;
        line-height: 50px;
        top: -45px;
    }

    .banner__content__top {
        -webkit-text-stroke-width: 2px;
    }

    .banner__content span {
        display: inline-block;
        padding: 0 15px;
    }

    .banner__top {
        padding: 18px 11%;
    }

    .banner__logo .menu__left__logo {
        top: 0;
        left: 0;
    }
}

@media screen and (max-width: 991px) {
    .front-page-data .banner {
        height: 492px;
    }

    .banner {
        height: 320px;
    }

    .banner img {
        height: 320px;
    }

    .banner__logo img {
        width: 350px !important;
        height: auto !important;
    }

    .front-page-data .banner img {
        height: 492px;
    }
}

@media screen and (max-width: 575px) {
    .front-page-data .banner {
        height: 285px;
    }

    .front-page-data .banner img {
        height: 285px;
    }

    .banner {
        height: 240px;
    }

    .banner img {
        height: 240px;
    }

    .banner__logo img {
        width: 100% !important;
        height: auto !important;
    }
}

@media screen and (max-width: 375px) {
    .banner__logo {
        left: 20px;
        right: 20px;
    }
}
