.menu__trigger {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    z-index: 9;
    right: 150px;
    top: 27px;
    color: $headings-color;
    font-family: $nexa-black;
    font-size: 1.438rem;
    cursor: pointer;
    padding: 26px;
    margin: -30px;
    background: none;
    border: none;

    &:hover {
        .menu__trigger__line1 {
            width: 25px;
            transition: all 150ms ease-in-out;
        }

        .menu__trigger__line2 {
            width: 35px;
            transition: all 150ms ease-in-out;
        }
    }
}

.menu__trigger__lines {
    display: inline-flex;
    flex-direction: column;
    margin-right: 14px;
    align-items: flex-end;
    margin-top: 1px;
    color: $primarycolor;
}

.menu__trigger__line1 {
    width: 35px;
    background-color: $primarycolor;
    height: 2px;
    margin-bottom: 5px;
    transition: all 150ms ease-in-out;
}

.menu__trigger__line2 {
    width: 25px;
    background-color: $primarycolor;
    height: 2px;
    transition: all 150ms ease-in-out;
}

.menu {
    position: relative;
    z-index: 99;

    .menu__trigger {
        pointer-events: none;
        opacity: 0;
        color: $body-font-color;
        position: fixed;
    }
}

.menu__left {
    position: fixed;
    left: 0;
    top: 0;
    width: 0;
    height: 100%;
    z-index: 2;
    pointer-events: none;
    opacity: 0;

    .menu__left__logo {
        position: absolute;
    }
}

.video__banner__long {
    position: absolute;
    z-index: 1;
    cursor: pointer;
    opacity: 1;
}

.video__banner__short {
    position: relative;
    z-index: 2;
    opacity: 1;
}

.menu__left__bg {
    background-color: rgba(#000, 0.82);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
}

.menu__left__bg__img {
    filter: grayscale(100%);
    background-image: url('../../images/menu-bg.jpg');
    background-size: cover;
    background-position: center center;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 0;
}

.menu__left__content {
    position: relative;
    z-index: 2;
}

.menu__left__logo {
    top: 100px;
    left: 250px;
    transition: initial;
    opacity: 0;
}

.menu__right {
    background-color: #fff;
    position: fixed;
    left: 50%;
    top: 0;
    width: 0;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1;
    pointer-events: none;
    opacity: 1;
}

.menu__left__items {
    .header__menu {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 1;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .nav {
        display: flex;
        margin-left: -150px;
        flex-direction: column;
    }

    .menu-item {
        opacity: 0;
        position: relative;
        left: 100px;
    }

    a {
        color: #fff;
        font-family: $nexa-black;
        font-size: 3.125rem;
        line-height: 2;
        position: relative;

        &:hover,
        &:focus {
            color: $primarycolor;

            .menu__left__count {
                color: #fff !important;
            }
        }
    }

    .current_page_item {
        a {
            &::after {
                content: '';
                position: absolute;
                top: 54%;
                width: 100%;
                height: 3px;
                background-color: #fff;
                left: 0;
                transition: all 300ms ease-in-out;
            }

            &:hover,
            &:focus {
                &::after {
                    background-color: #e5007d;
                    transition: all 300ms ease-in-out;
                }
            }
        }
    }
}

.menu__left__count {
    position: absolute;
    right: -25px;
    top: -15px;
    width: 34px;
    height: 34px;
    display: inline-flex;
    align-items: center;
    background-color: $primarycolor;
    justify-content: center;
    font-family: $nexa-black;
    color: #fff;
    border-radius: 100%;
    font-size: 1.25rem;
    transform: scale(0);
}

.menu__right__outer {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-wrap: wrap;
    width: 100%;
    max-width: 700px;
    margin: 200px auto 0;
    transform: translateX(10%);
}

.menu__right__item {
    flex: 0 0 300px;
    max-width: 300px;
    margin-bottom: 80px;
    opacity: 0;

    &:last-child {
        margin-bottom: 0;
    }

    li {
        padding: 5px 0;
        font-size: 1.125rem;
    }

    a {
        color: $body-font-color;
    }

    &:nth-child(2) {
        flex: 0 0 250px;
        max-width: 250px;
        margin-left: 100px;
    }

    &:nth-child(3) {
        flex: 0 0 100%;
        max-width: 100%;
    }
}

.menu__right__title {
    font-family: $nexa-black;
    font-size: 1.563rem;
    margin-bottom: 25px;
}

.menu__right__list {
    padding: 0;
    margin: 0;
    list-style: none;

    a {
        &.menu__right__list__contact__link {
            text-decoration: underline;
        }

        &:hover {
            text-decoration: underline;
        }
    }
}

@media screen and (max-width: 1499px) {
    .menu__right__item:nth-child(2) {
        margin-left: 0;
    }

    .menu__left__logo {
        top: 50px;
        left: 170px;
    }

    .menu__left__items .nav {
        margin-left: 0;
    }

    .menu__right__outer {
        transform: translate(0);
        max-width: 286px;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        margin: 0 auto;
        padding-top: 100px;
    }

    .menu__right__item {
        max-width: initial !important;
        flex: 0 0 auto !important;
        margin-bottom: 50px;
        top: 0;
    }

    .menu__right__title {
        margin-bottom: 10px;
    }

    .menu__left__items a {
        font-size: 2.75rem;
    }
}

@media screen and (max-width: 1199px) {
    .menu__trigger {
        right: 11%;
        top: 25px;
    }

    .mac .video__banner__long {
        z-index: 5;
    }
}

@media screen and (max-width: 991px) {
    .menu__trigger {
        top: 23px;
    }

    .menu__left__items a {
        font-size: 2.125rem;
    }

    .menu__left__count {
        line-height: 1.2;

        span {
            position: relative;
            top: 3px;
        }
    }

    .menu__right__title {
        font-size: 1.25rem;
    }

    .menu__right__item {
        font-size: 1rem;
    }

    .menu__right__outer {
        width: 286px;
    }

    .menu__left__items .header__menu {
        justify-content: flex-start;
        padding: 0 70px;
    }

    .menu__right__item li {
        padding: 2px 0;
    }
}

@media screen and (max-width: 767px) {
    .menu__left {
        height: 472px;
        position: absolute;
    }

    .menu__left__bg__img {
        background-size: 472px;
    }

    .menu__left__items .current_page_item a::after {
        top: 50%;
    }

    .menu {
        position: fixed;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        overflow: auto;
        pointer-events: none;
    }

    .menu__left__logo {
        top: 30px;
        left: 170px;

        img {
            width: 245px;
        }
    }

    .menu .menu__trigger {
        color: #fff;
        position: absolute;
        right: 60px;
        top: 40px;

        svg {
            path {
                fill: #fff;
            }
        }
    }

    .menu__trigger__line1,
    .menu__trigger__line2 {
        background: #fff;
    }

    .menu__left__items .header__menu {
        top: initial;
        bottom: 0;
        height: calc(100% - 80px);
    }

    .menu__right {
        top: 472px;
        position: absolute;
        left: 0;
        height: auto;
    }

    .menu__right__outer {
        width: 100%;
        max-width: 100%;
        padding: 100px 70px 0;
    }

    .menu__trigger__open {
        position: fixed;
        bottom: 0;
        top: initial;
        right: 0;
        z-index: 9;
        padding: 0;
        margin: 0;
        border-top-left-radius: 100px;
        height: 56px;
        width: 56px;
        background-color: $primarycolor;

        .menu__trigger__text {
            display: none;
        }

        .menu__trigger__lines {
            margin: 10px 0 0 10px;
            align-items: flex-start;
        }

        .menu__trigger__line1 {
            width: 23px;
        }

        .menu__trigger__line2 {
            width: 17px;
        }

        &:hover .menu__trigger__line1 {
            width: 23px;
        }

        &:hover .menu__trigger__line2 {
            width: 17px;
        }
    }
}

@media screen and (max-width: 575px) {
    .menu__left__logo {
        top: 43px;

        img {
            width: 100px;
        }
    }
}

@media screen and (max-width: 420px) {
    .menu__left {
        height: 400px;
        position: absolute;
    }

    .menu__right {
        top: 400px;
    }

    .menu__right__item {
        margin-bottom: 35px;
    }

    .menu__left__logo {
        left: 140px;
    }

    .menu .menu__trigger {
        right: 40px;
    }

    .menu__left__items .header__menu {
        padding: 0 40px;
    }

    .menu__right__outer {
        padding: 90px 40px 0;
    }
}
