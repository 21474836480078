.btn {
    position: relative;
    padding: 10px 40px 14px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border: 0;
    border-radius: $border-radius-btn;
    margin-right: 8px;
    color: #fff;
    font-size: 1.188rem;
    font-family: $graphik-bold;
    text-transform: uppercase;

    &:hover,
    &:active,
    &:focus {
        box-shadow: none !important;
        color: #fff;
    }

    &:last-child {
        margin-right: 0;
    }
}

.btn--primary {
    background-color: $primarycolor;

    &:hover,
    &:active,
    &:focus {
        background-color: lighten($primarycolor, 5) !important;
    }
}

.btn--secondary {
    background-color: $secondarycolor;

    &:hover,
    &:active,
    &:focus {
        background-color: lighten($secondarycolor, 5) !important;
    }
}

.btn--warempel {
    background-color: #ff006c;

    &:hover,
    &:active,
    &:focus {
        background-color: darken(#ff006c, 10) !important;
    }
}

.btn--exapps {
    background-color: #131480;

    &:hover,
    &:active,
    &:focus {
        background-color: darken(#131480, 5) !important;
    }
}

.btn--next {
    background-color: $primarycolor;
    padding-right: 38px;

    &::after {
        content: '';
        position: absolute;
        right: 15px;
        top: 50%;
        margin-top: -9px;
        width: 7px;
        height: 18px;
        background-image: url('../../images/angle-right-regular-white.svg');
        background-size: 7px 18px;
    }

    &:hover,
    &:active,
    &:focus {
        background-color: lighten($primarycolor, 5) !important;
    }
}

.btn--prev {
    background-color: $primarycolor;
    padding-left: 40px;

    &::before {
        content: '';
        position: absolute;
        left: 17px;
        top: 50%;
        margin-top: -9px;
        width: 7px;
        height: 18px;
        background-image: url('../../images/angle-left-regular-white.svg');
        background-size: 7px 18px;
    }

    &:hover,
    &:active,
    &:focus {
        background-color: lighten($primarycolor, 5) !important;
    }
}

.btn--open {
    color: #fff;
    border: 1px solid #fff;
    background-color: transparent;
    transition: all 0.3s;

    &:hover,
    &:active,
    &:focus {
        background-color: transparent;
        color: #fff !important;
        border-color: #fff;
    }
}

.btn-danger {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    margin-top: 30px;

    svg {
        width: 15px;
        height: 15px;
        margin-left: 10px;
    }
}

.btn--grey,
.btn--readmore {
    background-color: #eff3f5;
    color: #000;
    text-transform: none;
    font-size: 1.313rem;
    font-family: $graphik-semibold;
    padding: 8px 34px 12px;
    margin-top: 20px;

    svg {
        color: #000;
        width: 13px;
        margin-top: 8px;
        margin-left: 7px;
    }

    &:hover,
    &:active,
    &:focus {
        color: #000;
        background-color: darken(#eff3f5, 5) !important;
    }
}

.btn--readmore {
    font-family: $graphik-bold;
}

.btn--readmore--none {
    display: none;

    svg {
        transform: rotate(180deg);
    }
}

.frm_button_submit {
    @extend .btn;
    @extend .btn--primary !optional;
}

@media (max-width: 767px) {
    .btn {
        padding: 12px 40px;
        font-size: 0.938rem;
    }
}

.btn--skip {
    overflow: hidden;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2147483647;

    &:not(:focus):not(:active) {
        clip: rect(0 0 0 0);
        clip-path: inset(50%);
        height: 1px;
        white-space: nowrap;
        width: 1px;
    }
}
