.frm_dropzone .dz-preview.dz-success .dz-progress .dz-upload {
    background: #6da166 !important;
    background: linear-gradient(to bottom, #6da166, #5fc16b) !important;
}

.frm_form_field {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;

    input,
    select {
        height: 64px;
    }

    input,
    select,
    textarea {
        border-radius: 10px;
        border: 2px solid #e8e8e8;
        padding: 10px 15px;
    }
}

.frm_multi_upload {
    margin-top: 0 !important;
}

.dz-message {
    margin: 0 !important;
}

.frm_error {
    font-size: 0.875rem;
    margin: 5px 0 0;
    color: #ff6262;
}

.frm_required {
    color: $primarycolor;
}

.frm_primary_label {
    font-family: $nexa-black;
}

.form__block {
    max-width: 936px;
    padding: 70px 90px;
    border-radius: $border-radius;
    background-color: #eff3f5;
}

.form__content {
    margin-bottom: 45px;
}

.form__title {
    font-family: $nexa-black;
    font-size: 3.125rem;
    text-transform: uppercase;
}

.gform_drop_instructions {
    display: none;
}

.gfield {
    margin-bottom: 20px;

    input,
    select,
    textarea {
        background-color: #fff;
        box-shadow: 0 0 0 transparent;
        border-radius: $border-radius-form;
        height: 64px;
        border: 2px solid #eff3f5;
        padding: 10px 15px;
        width: 100%;
        font-size: 0.938rem;

        &:focus {
            border-radius: $border-radius-form;
            outline: none;
        }

        &::placeholder {
            color: #bebebe;
        }
    }

    textarea {
        height: auto;
    }

    .ginput_container_date {
        position: relative;

        input {
            padding-left: 57px;
        }

        &::before {
            content: '';
            position: absolute;
            left: 1px;
            top: 1px;
            width: 41px;
            height: calc(100% - 2px);
            background-color: #eee;
            border-right: 1px solid #eee;
            border-radius: $border-radius-form 0 0 $border-radius-form;
            background-image: url('../../svg/calendar-alt-regular.svg');
            background-size: 15px 16px;
            background-position: center;
            background-repeat: no-repeat;
            pointer-events: none;
        }
    }

    .ginput_container_fileupload {
        position: relative;

        .validation_message {
            display: none;
        }
    }
}

.gform_fileupload_rules {
    margin-top: 15px;
    font-size: 0.875rem;
    display: block;
}

.gform_submission_error {
    font-size: 0.875rem;
    color: #ef5350;
}

.gform_description {
    margin-bottom: 23px;
    display: block;
}

.gfield_label {
    font-family: $nexa-black;
    font-size: 1.25rem;
    margin-bottom: 10px;
}

.gfield_checkbox {
    padding: 0;
    margin: 0;
    list-style: none;

    > li {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        margin-bottom: 10px;

        label {
            margin: 0;
            display: flex;
            align-items: flex-start;
            position: relative;
            cursor: pointer;
            font-weight: 400;

            &::before,
            &::after {
                content: " ";
                display: inline-block;
            }

            &::before {
                width: 15px;
                min-width: 15px;
                height: 15px;
                min-height: 15px;
                border: 1px solid #d5d5d5;
                border-radius: 3px;
                margin-top: 5px;
                margin-right: 15px;
            }
        }

        input {
            opacity: 0;
            width: 0;
            visibility: hidden;
            position: absolute;

            &:checked + label {
                &::after {
                    content: "";
                    width: 12px;
                    height: 9px;
                    background-image: url("../../images/checkbox-solid.svg");
                    background-repeat: no-repeat;
                    background-position: center;
                    background-size: 12px 9px;
                    position: absolute;
                    left: 2px;
                    top: 8px;
                }
            }
        }
    }
}

.clear-multi {
    display: flex;
    margin: 0 -10px;

    > * {
        padding: 0 10px;
    }
}

.ginput_container_list {
    table {
        width: 100%;
    }

    tbody {
        display: flex;
        flex-direction: column;

        > tr {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            width: 100%;

            .gfield_list_cell {
                width: 100%;
            }

            .gfield_list_icons {
                display: flex;
                align-items: center;
                justify-content: center;

                > a {
                    margin: 0 3px;
                }
            }
        }
    }
}

.ginput_container_select {
    position: relative;

    &::after {
        content: "";
        background-image: url("../../images/angle-down-regular.svg");
        background-repeat: no-repeat;
        background-size: 10px 16px;
        background-position: center;
        position: absolute;
        right: 15px;
        top: 50%;
        transform: translateY(-50%);
        width: 10px;
        height: 16px;
    }

    .gfield_select {
        appearance: none;
        padding-top: 7px;

        &::-ms-expand {
            display: none; /* remove default arrow in IE 10 and 11 */
        }
    }
}

.gfield_time_ampm {
    min-width: 85px;
    font-size: 0.875rem;
}

.ginput_container_name {
    display: flex;
    margin: 0 -15px;

    > span {
        padding: 0 15px;
        flex: 0 0 50%;
        max-width: 50%;
    }

    label {
        display: block;
        order: 0;
    }
}

.ginput_container_post_image {
    input {
        padding: 4px;
    }
}

.ginput_container_fileupload {
    > input {
        padding: 4px;
    }
}

.ginput_container_address {
    > span {
        margin-bottom: 15px;
        display: block;

        > label {
            display: block;
            margin-top: 4px;
        }
    }
}

.ginput_container_consent {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    margin-top: -15px;

    a {
        text-decoration: underline;
        color: #138e9d;
    }

    > input {
        width: auto;
        height: 23px;
        margin-right: 15px;
    }

    > label {
        margin-bottom: 0;
    }
}

.gfield_radio {
    padding: 0;
    margin: 0;
    list-style: none;

    > li {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        margin-bottom: 10px;

        label {
            margin: 0;
            display: flex;
            align-items: flex-start;
            position: relative;
            cursor: pointer;
            font-weight: 400;

            &::before,
            &::after {
                content: "";
                display: inline-block;
            }

            &::before {
                width: 15px;
                min-width: 15px;
                height: 15px;
                min-height: 15px;
                border: 1px solid #d5d5d5;
                border-radius: 50px;
                margin-top: 5px;
                margin-right: 15px;
            }
        }

        input {
            opacity: 0;
            width: 0;
            visibility: hidden;
            position: absolute;

            &:checked + label {
                &::after {
                    content: "";
                    width: 9px;
                    height: 9px;
                    background-color: $primarycolor;
                    border-radius: 50%;
                    position: absolute;
                    left: 3px;
                    top: 8px;
                }
            }
        }
    }
}

.ginput_container_multiselect {
    select {
        height: auto;
    }
}

.gfield_required {
    margin-left: 4px;
    display: inline-block;
    color: $primarycolor;
    position: relative;
    top: -2px;
}

.gform_fields {
    padding: 0;
    margin: 0 -15px;
    list-style: none;
    display: flex;
    flex-wrap: wrap;

    > li {
        flex: 0 0 100%;
        max-width: 100%;
        padding: 0 15px;

        &.gform_hidden {
            height: 0 !important;
            overflow: hidden !important;
            margin: 0 !important;
            padding: 0 !important;
            position: absolute !important;
        }

        &.full {
            flex: auto;
            width: 100%;
            max-width: 100%;
        }
    }
}

.validation_error {
    color: #ef5350;
    font-size: 0.875rem;
    font-weight: 500;
    margin: 0 0 15px;
}

.validation_message {
    position: relative;
    display: flex;
    color: #ef5350;
    font-size: 0.75rem;
    font-weight: 500;
    margin: 5px 0 0;
    align-items: center;

    &::after {
        content: '';
        position: relative;
        margin: 0 0 0 auto;
        width: 14px;
        height: 12px;
        background-image: url('../../images/exclamation-triangle-solid-red.svg');
        background-size: 14px 12px;
    }
}

.gform_button {
    margin: 15px 0 0;
}

.gform_ajax_spinner {
    position: relative;
    top: 6px;
    left: 10px;
    width: 30px;
    height: 30px;
}

#ui-datepicker-div {
    background: #fff;
    border: 1px solid #ddd;
    border-radius: 4px;
    box-shadow: (0 3px 5px 0 rgba(50, 50, 50, 0.75));
    display: none;
    padding: 20px;
    width: 300px;

    .ui-icon {
        color: transparent;
        cursor: pointer;
        font-size: 0;
    }

    .ui-icon::before {
        font-size: 1.125rem;
    }

    .ui-datepicker-prev {
        float: left;
        width: 10%;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;

        .ui-icon::before {
            content: '';
            background-image: url('../../images/angle-left-regular.svg');
            float: left;
            background-size: 9px;
            width: 10px;
            height: 19px;
            background-repeat: no-repeat;
            position: relative;
        }

        &.ui-state-disabled {
            display: none;
        }
    }

    .ui-datepicker-next {
        float: right;
        width: 10%;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;

        .ui-icon::before {
            content: '';
            background-image: url('../../images/angle-right-regular.svg');
            float: right;
            background-size: 9px;
            width: 10px;
            height: 19px;
            background-repeat: no-repeat;
            position: relative;
        }

        &.ui-state-disabled {
            display: none;
        }
    }

    .ui-datepicker-header {
        display: block;
        float: left;
        width: 100%;
        margin-top: 12px;
    }

    .ui-datepicker-title {
        select {
            float: left;
            width: 70%;
        }

        .ui-datepicker-month {
            margin: 0 5% 6px;
            border-radius: 4px;
            padding: 3px;
            border: 1px solid #cfcfcf;
        }

        .ui-datepicker-year {
            margin: 0 15% 6px;
            border-radius: 4px;
            padding: 3px;
            border: 1px solid #cfcfcf;
        }
    }

    .ui-datepicker-today {
        background-color: #e6eef1;
        border-radius: 4px;

        a {
            color: #2a4982;
        }
    }

    table {
        width: 100%;

        td,
        th {
            text-align: center;
        }

        td {
            a {
                display: block;
                padding: 5px;
                border-radius: 4px;

                &:hover {
                    background-color: $primarycolor;
                    color: #fff;
                    text-decoration: none;
                }

                &.ui-state-active {
                    background-color: $primarycolor;
                    color: #fff;
                    text-decoration: none;
                }
            }
        }
    }
}

@media screen and (max-width: 767px) {
    .gform_fields > li {
        flex: 0 0 100%;
        max-width: 100%;
    }

    .gfield_label {
        font-size: 1rem;
    }

    //.gform_button_select_files {
    //    width: 180px;
    //
    //    &::after {
    //        font-size: 1.063rem;
    //    }
    //}

    .gform_button {
        margin-top: 0;
    }
}
