.social__icons {
    display: flex;
    list-style: none;
    padding: 0;
    margin: 0;

    li:last-child {
        a {
            margin-right: 0;
        }
    }
}

.social__icon__item {
    margin-right: 10px;

    svg {
        width: 20px;
        height: 20px;
    }
}
