$amount: 5;

.join-us {
    background-color: $tertiarycolor;
    padding: 120px 0 0 0;
    position: relative;

    &::before {
        position: absolute;
        top: 100%;
        left: 0;
        width: 100%;
        height: 50px;
        content: '';
        background: transparent;
        background: linear-gradient(0deg, rgba(220, 225, 227, 0) 0%, rgba(220, 225, 227, 1) 100%);
        z-index: 1;
    }
}

.join-us__container {
    position: relative;
    overflow: hidden;
}

.join-us__grid {
    display: grid;
    grid-template-columns: repeat($amount, 1fr);
    gap: 20px;
    width: 120%;
    min-width: calc(1494px);
    margin-left: calc((100% / #{$amount}) * -1);
}

.join-us__item {
    border-radius: 20px;
    position: relative;
    overflow: hidden;

    &:nth-of-type(n+6) {
        transform: translateX(50%);
    }

    &::before {
        content: '';
        display: block;
        height: 0;
        width: 0;
        padding-bottom: calc(172 / 233 * 100%);
    }
}

.join-us__image {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    height: 100%;
    object-fit: cover;

    &:nth-child(2) {
        opacity: 0;
        z-index: 1;
        animation-name: join-us-image;
        animation-duration: 30s;
        animation-iteration-count: infinite;
    }
}

.join-us__title {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: calc(100vw - 200px);
    z-index: 1;
    font-size: $body-font-size;
    font-family: $nexa-black;
    text-transform: uppercase;
    fill: transparent;
    stroke: $tertiarycolor;
    stroke-width: 0.25%;
    transition: fill 300ms ease-in-out;
    cursor: default;

    &:hover {
        fill: $tertiarycolor;
    }
}

@keyframes join-us-image {
    0% {
        opacity: 0;
    }

    5% {
        opacity: 1;
    }

    50% {
        opacity: 1;
    }

    55% {
        opacity: 0;
    }

    100% {
        opacity: 0;
    }
}

.video__outer {
    max-width: 936px;
    width: 100%;
    background-color: #fff;
    border-radius: 30px;
    padding: 20px;
    box-shadow: 0 0 50px rgba(#000, 0.16);
    display: inline-flex;
}

.video__button {
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 2;
    transform: translate(-50%, -50%);
    cursor: pointer;
    margin: 0;
}

.video {
    margin-top: -400px;
    position: relative;
    z-index: 1;
    margin-bottom: 90px;
    transform: translateY(100px);
    opacity: 0;

    video {
        width: calc(100% + 2px);
        transform: translateX(-1px);
    }
}

.video__mute {
    position: absolute;
    z-index: 9;
    opacity: 0;
    right: 15px;
    bottom: 15px;
    width: 50px;
    height: 40px;
    cursor: pointer;
    background: none;
    border: none;

    &.hide {
        .video__mute__on {
            display: none;
        }

        .video__mute__off {
            display: flex;
            justify-content: center;
        }
    }

    svg {
        height: 100%;
    }
}

.video__mute__on {
    display: flex;
    justify-content: center;
}

.video__mute__off {
    display: none;
}

.video__pause {
    position: absolute;
    z-index: 9;
    opacity: 0;
    left: 15px;
    bottom: 15px;
    width: 50px;
    height: 40px;
    cursor: pointer;
    background: none;
    border: none;

    &.hide {
        .video__pause__on {
            display: none;
        }

        .video__pause__off {
            display: flex;
            justify-content: center;
            height: 100%;
        }
    }

    svg {
        height: 100%;
    }
}

.video__pause__on {
    display: flex;
    justify-content: center;
    height: 100%;
}

.video__pause__off {
    display: none;
}

.video__bg {
    background-color: rgba(#003277, 0.35);
    position: absolute;
    top: 0;
    width: 50%;
    height: 100%;
    z-index: 1;
}

.video__bg__left {
    left: 0;
}

.video__bg__right {
    left: 50%;
}

.video__inner {
    position: relative;
    border-radius: 15px;
    display: inline-flex;
    overflow: hidden;
    width: 100%;
}

.video__button__line1 {
    width: 3px;
    height: 254px;
    background-color: #fff;
    position: absolute;
    left: 50%;
    top: 50%;
    z-index: 1;
    margin-left: 0;
    margin-top: -127px;
    transform: rotate(0);
}

.video__inner__content {
    position: absolute;
    z-index: 3;
    width: 100%;
    height: 100%;
}

.video__button__line2 {
    width: 3px;
    height: 254px;
    background-color: #fff;
    position: absolute;
    left: 50%;
    top: 50%;
    z-index: 1;
    margin-left: 0;
    margin-top: -127px;
    transform: rotate(0);
}

.front-intro {
    margin-top: 55px;
    margin-bottom: 115px;
}

.mockup {
    height: 837px;
    background-color: #e42355;
    display: flex;
    align-items: center;
    position: relative;
    overflow: hidden;
}

.mockup__text {
    text-transform: uppercase;
    font-size: 4.313rem;
    color: transparent;
    font-family: $nexa-black;
    -webkit-text-stroke-width: 3px;
    -webkit-text-stroke-color: #efefef;
    line-height: 1.2;

    p,
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        font-size: inherit;
        font-weight: inherit;
        color: inherit;
    }

    p {
        margin-bottom: 0;
    }

    strong {
        color: #fff;
        -webkit-text-stroke-width: 0;
    }
}

.mockup__img {
    margin-left: 50px;
}

.mockup__bg {
    position: absolute;
    left: 0;
    opacity: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: $primarycolor;
    z-index: 1;
}

.mockup__inner {
    display: flex;
    justify-content: space-between;
    align-items: center;
    opacity: 0;
    position: absolute;
    top: 0;
    height: 100%;
    z-index: 2;

    &.mockup__active {
        opacity: 1;
    }
}

.mockup__triangle__top {
    display: inline-block;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 0 837px 331px;
    border-color: transparent transparent #eff3f5 transparent;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 3;
}

.mockup__triangle__bot {
    display: inline-block;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 0 837px 372px;
    border-color: transparent transparent #e1e7ea transparent;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 3;
}

.mockup__next {
    position: absolute;
    right: 120px;
    bottom: 70px;
    text-transform: uppercase;
    color: $primarycolor;
    font-family: $nexa-black;
    font-size: 1.813rem;
    cursor: pointer;
    z-index: 3;
    pointer-events: all;
    transition: all 0.3s;

    &.stop {
        pointer-events: none;
    }

    &:hover {
        color: lighten($primarycolor, 8);
    }
}

@media screen and (max-width: 1900px) {
    .mockup__text {
        font-size: 3.75rem;
    }

    .mockup__img img {
        width: 650px;
    }

    .mockup__triangle__top {
        border-width: 0 0 837px 215px;
    }

    .mockup__triangle__bot {
        border-width: 0 0 837px 250px;
    }

    .mockup__next {
        right: 60px;
    }
}

@media screen and (max-width: 1480px) {
    .join-us__grid {
        position: relative;
        left: calc(50% + 200px);
        transform: translateX(-50%);
    }
}

@media screen and (max-width: 1399px) {
    .mockup__text {
        font-size: 2.813rem;
    }

    .mockup__img img {
        width: 600px;
    }

    .mockup {
        height: 650px;
    }

    .mockup__next {
        right: 60px;
        bottom: 30px;
    }

    .mockup__triangle__top {
        border-width: 0 0 650px 215px;
    }

    .mockup__triangle__bot {
        border-width: 0 0 650px 250px;
    }
}

@media screen and (max-width: 1199px) {
    .mockup {
        height: 860px;
    }

    .mockup__text {
        text-align: center;
        padding-top: 30px;
    }

    .mockup__inner {
        flex-direction: column;
        width: 100%;
        left: 0;
        justify-content: center;
    }

    .mockup__img {
        margin-left: 0;
    }

    .mockup__triangle__top {
        top: initial;
        border-width: 0 0 520px 215px;
        bottom: 0;
    }

    .mockup__triangle__bot {
        top: initial;
        border-width: 0 0 520px 240px;
        bottom: 0;
    }

    .video__outer {
        max-width: 80%;
        width: 100%;
    }

    .video {
        margin-top: -32%;
        margin-bottom: 40px;
    }

    .video__button__line1 {
        height: 120px;
        top: 50%;
        margin-top: -60px;
    }

    .video__button__line2 {
        height: 120px;
        top: 50%;
        margin-top: -60px;
    }

    .video__button {
        font-size: 0.875rem;
        padding: 8px 30px;
    }
}

@media screen and (max-width: 991px) {
    .video {
        margin-top: -34%;
        margin-bottom: 20px;
    }

    .mockup {
        height: 720px;
    }

    .mockup__img img {
        width: 450px;
    }

    .mockup__next {
        font-size: 1.5rem;
        right: 40px;
    }

    .mockup__triangle__top {
        border-width: 0 0 430px 180px;
    }

    .mockup__triangle__bot {
        border-width: 0 0 430px 200px;
    }

    .join-us {
        padding-top: 80px;
    }
}

@media screen and (max-width: 767px) {
    .mockup__triangle__top {
        border-width: 0 0 320px 150px;
    }

    .mockup__triangle__bot {
        border-width: 0 0 320px 165px;
    }

    .mockup__next {
        font-size: 1.375rem;
        right: 30px;
        bottom: 20px;
    }

    .mockup {
        height: 650px;
    }

    .mockup__img img {
        width: 380px;
        position: relative;
        top: 35px;
    }

    .mockup__text {
        -webkit-text-stroke-width: 1px;
        padding: 0;
    }

    .join-us {
        padding: 60px 0 0 0;
    }

    .banner__content span {
        padding: 0 10px;
    }

    .video {
        margin-bottom: 20px;
        margin-top: -40%;
    }

    .join-us__title {
        width: calc(180vw);
        left: 100%;
        transform: translateY(-50%);
        top: 60%;
    }

    .video__outer {
        padding: 10px;
        border-radius: 10px;
    }

    .video__inner {
        border-radius: 5px;
    }

    .video__mute {
        width: 30px;
        height: 30px;

        svg {
            width: 30px;
        }
    }

    .video__button__line1 {
        height: 80px;
        top: 50%;
        margin-top: -40px;
    }

    .video__button__line2 {
        height: 80px;
        top: 50%;
        margin-top: -40px;
    }

    .join-us__grid {
        left: calc(50% + 166px);
    }

    .front-intro {
        margin-top: 0;
        margin-bottom: 40px;
    }
}

@media screen and (max-width: 575px) {
    .mockup {
        height: 530px;
    }

    .mockup__text {
        font-size: 2.125rem;
    }

    .mockup__img img {
        width: 300px;
        top: 0;
    }

    .mockup__next {
        font-size: 1.125rem;
        right: 25px;
        bottom: 20px;
    }

    .mockup__triangle__top {
        border-width: 0 0 250px 120px;
    }

    .mockup__triangle__bot {
        border-width: 0 0 250px 130px;
    }

    .video {
        margin-top: -42%;
    }

    .video__button {
        font-size: 0.75rem;
        padding: 6px 20px;
    }

    .video__outer {
        max-width: 90%;
    }
}

@media screen and (max-width: 420px) {
    .video {
        margin-top: -46%;
    }
}

@media screen and (max-width: 380px) {
    .video {
        margin-top: -50%;
    }

    .mockup {
        height: 450px;
    }

    .mockup__triangle__top {
        border-width: 0 0 180px 110px;
    }

    .mockup__triangle__bot {
        border-width: 0 0 180px 120px;
    }

    .mockup__text {
        font-size: 1.875rem;
    }

    .mockup__img img {
        width: 235px;
        top: 0;
    }

    .mockup__next {
        font-size: 1rem;
        right: 20px;
        bottom: 20px;
    }
}
