// Page
.row--page {
    margin-top: 50px;

    &:first-child {
        margin-top: 0;
    }

    .col {
        > p {
            &:last-child {
                margin-bottom: 0;
            }
        }
    }

    &:last-child {
        margin-bottom: 0;
    }
}

.row--gallery {
    .col {
        &:nth-child(n+5) {
            margin-top: 30px;
        }
    }

    &:last-child {
        margin-bottom: 0;
    }

    img {
        border-radius: 20px;
    }
}

.row--form__title {
    @extend h3;
}

.row--form__content {
    margin-bottom: 15px;
}

.page__slider {
    position: relative;

    svg {
        color: #b9c9d1;
        transition: all 0.3s;

        &:hover {
            color: darken(#b9c9d1, 10);
        }
    }

    .slick-dots {
        bottom: -43px;
    }

    .page__slider__item {
        a {
            &:focus {
                outline: none;
            }
        }

        &:focus {
            outline: none;
        }
    }

    &.slick-dotted {
        &.slick-slider {
            margin-bottom: 40px;
        }
    }

    .slick-arrow {
        position: absolute;
        top: 50%;
        width: 50px;
        height: 50px;
        margin-top: -25px;
        cursor: pointer;
        font-size: 2.25rem;
        text-align: center;

        &.fa-angle-left {
            left: -40px;
        }

        &.fa-angle-right {
            right: -40px;
        }
    }
}

.embed-container {
    position: relative;
    height: 0;
    padding-bottom: 56.25%;

    iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100% !important;
        height: 100% !important;
    }
}

@media screen and (max-width: 1275px) {
    .row--slider {
        padding: 0 40px;
    }
}

@media screen and (max-width: 991px) {
    .row--gallery {
        .col {
            &:nth-child(n+4) {
                margin-top: 30px;
            }
        }
    }
}

@media screen and (max-width: 767px) {
    .row--gallery {
        .col {
            &:nth-child(n+3) {
                margin-top: 30px;
            }
        }
    }

    .row--page {
        margin-top: 29px;
    }
}

@media screen and (max-width: 500px) {
    .row--gallery {
        > .col {
            flex: 0 0 100%;
            max-width: 100%;
            margin-top: 30px;

            &:first-child {
                margin-top: 0;
            }
        }
    }
}
