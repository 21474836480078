.mac {
    .menu__left__items .current_page_item a::after {
        top: 43%;
    }

    .banner__content {
        line-height: 79px;
    }

    .block__shortinfo__item svg {
        top: -3px;
    }
}

@media screen and (max-width: 1199px) {
    .mac {
        .banner__content {
            top: -46px;
            line-height: 52px;
        }
    }
}
